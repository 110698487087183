import { render, staticRenderFns } from "./confirmation-sms.vue?vue&type=template&id=52a9838a"
import script from "./confirmation-sms.vue?vue&type=script&setup=true&lang=ts"
export * from "./confirmation-sms.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./confirmation-sms.vue?vue&type=style&index=0&id=52a9838a&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsLisaAutocomplete: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/atoms/lisa-autocomplete/lisa-autocomplete.vue').default,AtomsInputBase: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/atoms/input-base/input-base.vue').default,Step: require('/vercel/path0/components/Step.vue').default,MoleculesLoader: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/molecules/loader/loader.vue').default,PopinSubscriptionError: require('/vercel/path0/components/PopinSubscriptionError.vue').default})
